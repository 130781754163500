import React, {ReactNode, useState} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export interface IAppHeaderProps {
    hideMenu?: boolean,
}

export default function AppHeader(props: IAppHeaderProps) {
    const {t} = useTranslation()

    return (
        <div className="header">
            <div className="appname h3-small-arial-16">
                SINTRA ESL UPLOAD
            </div>
            {
                !props.hideMenu && (
                    <div className="lo" >
                        <Link to="/logout"><span></span>{t("Logout")}</Link>
                    </div>
                )
            }
        </div>
    )
}
