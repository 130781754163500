import React, {useEffect, useRef, useState, useCallback} from 'react';
import {useTranslation} from "react-i18next";
import logo from '../logo.svg';
import Login from "../Login/Login";
import {Route, Navigate, NavLink, useNavigate} from "react-router-dom";
import {useRecoilState, useResetRecoilState} from "recoil";
import {userLoggedIn, menuIsVisible, networkLoadingStatus} from "../atoms";
import BarCodeScanButton from "../Components/BarCodeScanButton"
import globals from "../globals";
import DialogOk, {DialogError} from "../Components/DialogOk"
import AppHeader from "../Components/AppHeader";
import AppMenu from "../Components/AppMenu";
import config from "../config";
import {useDropzone} from 'react-dropzone'

type AssignData = {
    material_id: string,
    material_number: string,
    material_1: string,
    material_TrayNo: string,
    material_3: string,

    beacon_container: string,
    container_id: string,
    container_number: string,

    esltag: string,
//    anyString: string,
}


const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};


const defaultData: AssignData = {
    material_id: "",
    material_number: "",
    material_1: "",
    material_TrayNo: "",
    material_3: "",

    beacon_container: "",
    container_id: "",
    container_number: "",

    esltag: "",
}

export default function Main() {
    const {t, i18n} = useTranslation()
//    let navigate = useNavigate();
    const [user, setUser] = useRecoilState(userLoggedIn);
//    const resetUserState = useResetRecoilState(userLoggedIn);
    const [isMenuVisible, setMenuVisible] = useRecoilState(menuIsVisible)
    const [networkLoadingState] = useRecoilState(networkLoadingStatus);

    const [anyString, setAnyString] = useState("");

    // const [barcodeContainer, setContainerBarCode] = useState("00123");
    // const [barcodeBeacon, setBeaconBarCode] = useState("00110");
    // const [barcodeEsl, setEslBarCode] = useState("A0A3B8274E5B2");
    const [imger, setImg] = useState("")

    const [eslTagValue, setEslTagValue] = useState("")
    const [assignData, setAssignData] = useState(defaultData)

    const [isOpen, setIsOpen] = useState(false)
    const [isOpenError, setIsOpenError] = useState(false)
    const [szErrorMessage, setErrorMessage] = useState("")

    const [files, setFiles] = useState([]);

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        acceptedFiles.forEach((file: any) => {
            // const reader = new FileReader()
            //
            // reader.onabort = () => console.log('file reading was aborted')
            // reader.onerror = () => console.log('file reading has failed')
            // reader.onload = () => {
            //     // Do whatever you want with the file contents
            //     const binaryStr = reader.result
            //     console.log(binaryStr)
            // }
            // reader.readAsArrayBuffer(file)

            setFiles(acceptedFiles.map((file: any) => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        })

    }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: 'image/png',
        onDrop: onDrop,
        maxFiles: 1,
//        noClick: true,
    })

    // useEffect(() => {
    //     // Make sure to revoke the data uris to avoid memory leaks
    //     files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    // }, [files]);

    const onUpdateEsl = (e: any) => {
        setEslTagValue(e.target.value)
    }

    const clickReset = () => {
        // setEslBarCode("")
        // setBeaconBarCode("")
        // setContainerBarCode("")
        setAssignData({
            material_id: "",
            material_number: "",
            material_1: "",
            material_TrayNo: "",
            material_3: "",
            beacon_container: "",
            container_id: "",
            container_number: "",
            esltag: "",
        })
        setAnyString("")
    }

    const setError = (data: string) => {
        setErrorMessage(data)
    }

    const closeDialog = () => {
        setIsOpen(false)
        clickReset()
    }

    const closeDialogError = () => {
        setIsOpenError(false)
    }


    const onScanEsl = (data: string, isCancelled: boolean, format: string) => {
        if (!isCancelled) {
//            setEslBarCode(data)
            if (isValidEslTag(data)) {
                setAssignData({
                    ...assignData,
                    esltag: data
                })
            }
        }
    }

    const analyzeString = () => {
        let isError = false
        let val = {...assignData}

        if (isValidEslTag(anyString)) {
            //setContainerBarCode(anyString)
            val.esltag = anyString
        } else {
            // error
        //    setErrorMessage("Possible Wrong Scan")
        //    setIsOpenError(true)
            isError = true
        }
        // else
        // if (val.anyString.length > 6) {
        //     //setEslBarCode(anyString)
        //     val.esltag = val.anyString
        // }

        //anyString = ""
        if (!isError) {
            setAssignData(val)
        }
        setAnyString("")
        // setTimeout(()=>{
        //     globals.log(barcodeEsl + ' - ' + barcodeContainer + ' = ' + barcodeBeacon)
        //     if (barcodeEsl && barcodeContainer && barcodeBeacon) {
        //         doAssignTogether()
        //     }
        // }, 300)
    }

    const isValidEslTag = (data: string): boolean => {
        if (data.length > 6) {
            return true
        }
        return false
    }

    const doRunLed = () => {
        if (!isValidEslTag(eslTagValue)) {
            setError("Wrong ESL")
            setIsOpenError(true)
            return
        }

        step_2_run_led(eslTagValue)
    }

    const doAssignTogether = () => {
        if (!isValidEslTag(eslTagValue)) {
            setError("Wrong ESL")
            setIsOpenError(true)
            return
        }

        if (files.length == 0) {
            setError("Picture not uploaded")
            setIsOpenError(true)
            return
        }

        let file: any = files[0]
        const reader = new FileReader()

        reader.onabort = () => globals.log('file reading was aborted')
        reader.onerror = () => globals.log('file reading has failed')
        reader.onload = () => {
            // Do whatever you want with the file contents
            const binaryStr = reader.result
            globals.log('file to upload: ' + file.name)
            globals.log(binaryStr)

            step_1_direct_upload(eslTagValue, binaryStr)
        }
        reader.readAsArrayBuffer(file)
    }

    const step_1_direct_upload = (eslTag: string, binaryData: any) => {
        globals.postRequest(user.url + "/apiv3/direct_tag_upload", binaryData,
            (response: any) => {
                globals.log('upload picture:', response)


                if (response.is_error == 0) {
                    if (response.data2) {
                        setImg(response.data)
                    }
                } else {
                    setError(response.err_msg)
                    setIsOpenError(true)
                }
            },
            (message: any) => {
                globals.log('Error:', message)
                setError(message)
                setIsOpenError(true)
            },
            false,
            {
                'esltag': eslTag,
                'content-type': 'application/octet-stream',
            })
    }

    const step_2_run_led = (eslTag: string) => {
        globals.postRequest(user.url + "/apiv3/direct_tag_flash", {
                'mac': eslTag,
            },
            (response: any) => {
                globals.log('flash tag:', response)

//                setImg(response)

                if (response.is_error == 0) {
                    setIsOpen(true)
                } else {
                    setError(response.err_msg)
                    setIsOpenError(true)
                }
            },
            (message: any) => {
                globals.log('Error:', message)
                setError(message)
                setIsOpenError(true)
            }
        )
    }






    const step_6_reset_init = () => {
        clickReset()
    }

    const onKeyDown = (e: any) => {
        if (isMenuVisible.isOpen || isOpen || isOpenError ) return

//        if (isMessageAfterClean || state.isLoading) return
//        console.log('keycode: !' + e.keyCode + '!', e)
        switch( e.keyCode ) {
            case 27:
                e.stopPropagation();
                clickReset()
                break;
            case 13:
                e.stopPropagation();
                analyzeString()
                break;
            default:
//                console.log('keycode: ?' + e.keyCode + '?'  + e.keyCode.toString(16) + '?')
                if (e.key.length == 1 && !e.ctrlKey && !e.altKey) {
                    e.stopPropagation();
                //    console.log('keycode: ?' + e.keyCode + '?'  + anyString + '?', e)
                    //setContainerBarCode(barcodeContainer + e.key)
                    setAnyString(anyString + e.key)
                    // setAssignData({
                    //     ...assignData,
                    //     anyString: assignData.anyString + e.key
                    // })
                    // setState({
                    //     ...state,
                    //     currentString: state.currentString + e.key
                    // })
                }
                break;
        }
    }


    const onMenuClick = () => {
        setMenuVisible({
            isOpen: !isMenuVisible.isOpen
        })
    }

    const inputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        inputRef?.current?.focus();
    }, [inputRef])

    if (!user.isLoggedIn) {
        return (
            <Navigate to="/login" replace />
        )
    }


    const thumbs = files.map((file: any) => {

        // const reader = new FileReader()

        // reader.onabort = () => console.log('file reading was aborted')
        // reader.onerror = () => console.log('file reading has failed')
        // reader.onload = () => {
        //     // Do whatever you want with the file contents
        //     const binaryStr = reader.result
        //     console.log('file: ' + file.name)
        //     console.log(binaryStr)
        // }
        // reader.readAsArrayBuffer(file)


        return (
            <div  key={file.name}>
                <div style={thumbInner}>
                    {/*<div>{file.name}</div>*/}
                    <img
                        src={file.preview}
                        style={img}
                    />
                </div>
            </div>
        )
    });

    return (
        <div className="App"
             onKeyDown={onKeyDown}
             tabIndex={0}
             ref={inputRef}
        >
            <div>
                <AppHeader />
                <div className="main mainwindow">


                    <div className="selectPict">
                        <div className="text">
                            <h2>{t('Please Scan ESL Tag')}</h2>
                        </div>
                        <div className="text">
                            <input type="text" value={eslTagValue}
                                   onChange={onUpdateEsl}
                                   placeholder={t('Enter ESL tag code')}/>
                        </div>
                    </div>
                    <div className="selectPict">

                        <div {...getRootProps()} className="dragdrop">
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p>Drop the files here ...</p> :
                                    <p>Drag 'n' drop PNG file here, or click to select files</p>
                            }
                            {
                                <div>
                                    <aside >
                                        {thumbs}
                                    </aside>
                                </div>
                            }
                        </div>


                    </div>
                    <div className="selectButtons">
                        <p>DEBUG: {anyString} / {networkLoadingState.counter}</p>
                        <button onClick={doAssignTogether}>{t("Upload picture to ESL TAG")}</button>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <button onClick={doRunLed}>{t("Run LED at ESL TAG")}</button>
                    </div>

                    <div>
                        <img src={imger} />
                    </div>
                    <p></p>

                    <DialogOk onClose={closeDialog} isOpen={isOpen} autoCloseMS={1000}>
                        <p>Everything fine...<br/>
                            No Errors</p>
                    </DialogOk>
                    <DialogError onClose={closeDialogError} isOpen={isOpenError} >
                        <p>{szErrorMessage}</p>
                    </DialogError>
                </div>
            </div>
            <AppMenu isOpen={isMenuVisible.isOpen} afterClick={onMenuClick} />
        </div>
    )
}
