import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.scss';
import {useTranslation} from "react-i18next";
import Main from './Main/Main'
import Login from "./Login/Login";
import NotFound from "./NotFound/NotFound";
import {
    Routes,
    Route,
} from "react-router-dom";
import Router from './router'
import Logout from "./Login/Logout";
import axios from './axios';
import {useRecoilState, useSetRecoilState} from "recoil";
import {networkLoadingStatus, onChangeNetworkLoadingStatus, userLoggedIn} from "./atoms";
import {DialogLoading} from "./Components/DialogOk";
import globals from "./globals";

//let i = 0;

function App() {
    const { t, i18n } = useTranslation()
    const [networkLoadingState] = useRecoilState(networkLoadingStatus);
    const [loadingState, setUpdateNetworkLoadingState] = useRecoilState(onChangeNetworkLoadingStatus);
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        globals.log('useEffect Application')

        //alert(1)
        if (navigator.splashscreen) {
             //alert(2)
            navigator.splashscreen.hide();
        }
         //alert(3)

    }, [])

    const setLoadingState = (value: boolean) => {
        globals.log("networkLoadingState: ", networkLoadingState)
        setUpdateNetworkLoadingState(value)
    }

    useEffect(()=>{
        setIsOpen(loadingState)
    }, [loadingState])

    useEffect(()=>{
        globals.log("axios.interceptors...")
        // setup 'Network Loading...'
        axios.interceptors.request.use((config) => {
            // trigger 'loading=true' event here
            setLoadingState(true)
            globals.log('axios RUN')
            return config;
        }, (error) => {
            // trigger 'loading=false' event here
            setLoadingState(false)
            globals.log('axios ERR')
            return Promise.reject(error);
        });

        axios.interceptors.response.use((response) => {
            // trigger 'loading=false' event here
            setLoadingState(false)
            globals.log('axios DONE')
            return response;
        }, (error) => {
            // trigger 'loading=false' event here
            globals.log('axios ERRR 2')
            setLoadingState(false)
            return Promise.reject(error);
        });

        return () => {
            globals.log("axios.interceptors OFF...")
        }
    }, [])

    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<Main />}>
                    </Route>
                    <Route path="/index.html" element={<Main />}>
                    </Route>
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Logout />} />

                    <Route path="/*" element={<NotFound />} />
                </Routes>
            </Router>
            <DialogLoading isOpen={isOpen} >
                <p>Loading...</p>
            </DialogLoading>
        </>
  );
}

export default App;
