import {atom, DefaultValue, GetRecoilValue, RecoilState, ResetRecoilState, selector, SetRecoilState} from "recoil";
import { UserLoggedIn, MenuIsVisible, NetworkLoadingStatus } from "./types";

//     "noImplicitAny": false
type SetType = {
    setSelf: any;
    onSet: any;
}

const localStorageEffect = (key: string) => ({setSelf, onSet}: SetType) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
    }

    onSet((newValue:UserLoggedIn, _:UserLoggedIn, isReset: boolean) => {
        isReset
            ? localStorage.removeItem(key)
            : localStorage.setItem(key, JSON.stringify(newValue));
    });
};


// const savedValue = localStorage.getItem('userLoggedIn')
// const value = (savedValue != null)
//     ? JSON.parse(savedValue)
//     : {
//         id: 0,
//         text: "ssssss",
//         isComplete: false,
//     }
//
//     console.log("value", value)

export const userLoggedIn = atom<UserLoggedIn>({
    key: 'userLoggedIn',
    default: {
        id: 0,
        name: "",
        url: "",
        isLoggedIn: false,
        token: "",
        company_id: 0,
    },
    effects: [
        localStorageEffect('userLoggedIn'),
    ],
});

export const menuIsVisible = atom<MenuIsVisible>({
   key: 'menuIsVisible',
    default: {
        isOpen: false,
    },
});

export const networkLoadingStatus = atom<NetworkLoadingStatus>({
    key: 'networkLoadingStatus',
    default: {
        counter: 0,
        isLoading: false,
    },
});

// export const onChange = selector<NetworkLoadingStatus>({
//     key: "onChange",
//     get: ({ get }) => {
//         return get(networkLoadingStatus)
//     },
//     set: ({ set, get }, newValue: NetworkLoadingStatus) => {
//         const networkLoadingState = get(networkLoadingStatus);
//
//         const counter = networkLoadingState.counter + newValue.counter
//         const newState: NetworkLoadingStatus = {
//             isLoading: counter > 0,
//             counter: counter
//         }
//         set(networkLoadingStatus, newState);
//     },
// });

export const onChangeNetworkLoadingStatus = selector<boolean>({
    key: "onChangeNetworkLoadingStatus",
    get: ({ get }) => {
        const net = get(networkLoadingStatus)
        return net.isLoading
    },
    set: ({ set, get }, newValue: boolean | DefaultValue) => {
        const networkLoadingState = get(networkLoadingStatus);

        const counter = networkLoadingState.counter + (newValue ? 1 : -1)
        const newState: NetworkLoadingStatus = {
            isLoading: counter > 0,
            counter: counter
        }
       set(networkLoadingStatus, newState);
    },
});