import config from "./config";
import md5 from "md5";
import axios from './axios';
import axiosSilent from './axiosSilent';
import {useResetRecoilState} from "recoil";
//import {userLoggedIn, networkLoading} from "./atoms";
import {UserLoggedIn} from "./types";

class Globals {
    protected token = ''

    constructor() {
        axios.interceptors.request.use(function (config) {
            let token = ''
            const userData = localStorage.getItem('userLoggedIn');

            if (userData) {
                let data = JSON.parse(userData)
                if (data && data.token) {
                    token = data.token
                }
            }
            config.headers.Authorization =  token ? `Bearer ${token}` : '';
            return config;
        });

        axiosSilent.interceptors.request.use(function (config) {
            let token = ''
            const userData = localStorage.getItem('userLoggedIn');

            if (userData) {
                let data = JSON.parse(userData)
                if (data && data.token) {
                    token = data.token
                }
            }
            config.headers.Authorization =  token ? `Bearer ${token}` : '';
            return config;
        });

    }

    log = (data: any, obj?: any) => {
        if (config.debug) {
            if (obj) {
                console.log(data, obj)
            } else {
                console.log(data)
            }
//        } else {
//            console.log('no debug')
        }
    }

    logout = () => {
        localStorage.removeItem('userLoggedIn')
        window.location.reload();
    }

    postRequest = (path: string, data: any, onSuccess: any, onError: any, silent = false, extraHeaders: any = {}) => {
        let axiosInstance = (silent) ?  axiosSilent : axios
        const isLogin = (path.indexOf('login') !== -1)

        let config: any = {}
        if (extraHeaders) {
            config['headers'] = extraHeaders
        }

        axiosInstance.post(path, data, config)
            .then((response) => {
                if (response.status == 200) {
                    if (response.data) {
                        let data = response.data
                        if (data.is_error == 1) {
                            // error
                            if (data.err_msg == 'Not authenticated' && !isLogin) {
                                this.logout()
                            } else
                            if (data.err_msg == 'unauthorized access' && !isLogin) {
                                this.logout()
                            } else {
                                onError(data.err_msg)
                            }
                        } else {
                            globals.log(data);
                            onSuccess(data)
                        }
                    } else {
                        onError(response)
                        globals.log(response);
                    }
                } else
                if (response.status == 401 && !isLogin) {
                    this.logout()
                } else {
                    onError(response)
                    globals.log(response);
                }
            })
            .catch((error) => {
                // if(error.toJSON().message === 'Network Error'){
                //     alert('no internet connection');
                //    // dispatch({type: RELOAD});
                // }
                onError(error.toJSON().message)
                globals.log('FATAL ERROR: ', error);
            });
    }
}


const globals = new Globals()

export default globals
