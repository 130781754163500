import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {RecoilRoot} from "recoil";
import './i18n';
import SuspenseDefaultLoader from './Suspense/SuspenseDefaultLoader'

declare global {
    interface Window {
        cordova: any
//        location: any
        // bluetoothle: any
        // device: any,
        // Camera: any,
        //
        // machines: any,
        // autoactivate: any,
    }
}
declare global {
    interface Navigator {
        splashscreen: any
        // notification: any
        // app: any,
        // camera: any,
    }
}

function startApp () {
    ReactDOM.render(
        <React.StrictMode>
            <RecoilRoot>
                <Suspense fallback={<SuspenseDefaultLoader/>}>
                    <App/>
                </Suspense>
            </RecoilRoot>
        </React.StrictMode>,
        document.getElementById('root')
    );
}


if (!window.cordova) {
    startApp();
} else {
    document.addEventListener('deviceready', startApp, false);
}

//startApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

