
const config = {
    url: "https://sintra-main.sinfosy.com",
    //url1: "https://sul.sinfosy.com/api/",
    //url2: "https://sul-in.sinfosy.com/api/",
    // url: "http://10.10.10.15/",
    // url1: "http://10.10.10.15/",
    // url2: "http://10.10.10.15/",

    login: "/apiv3/login",

    // prefix 0x05 when scanned or space for debug
    validContainerRangeFrom: 1,
    validContainerRangeTo: 99999,
    // our regular beacon === container_number
    validBeaconRangeFrom: 1,
    validBeaconRangeTo: 40000,

    debug: (process.env.REACT_APP_DEBUGENABLED == '1')
}

console.log('process.env.DEBUG: ', process.env.REACT_APP_DEBUGENABLED)
export default config