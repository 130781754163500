import React from 'react';
import {useTranslation} from "react-i18next";
import logo from '../logo.svg';
import Login from "../Login/Login";
import {Route, Navigate, NavLink, useNavigate} from "react-router-dom";

export default function SuspenseDefaultLoader() {

    return (
        <div className="App">
            <header className="App-header">
                <p>
                    Loading...
                </p>
            </header>
        </div>
    )
}
