import React, {useEffect, ReactNode} from "react";
import Modal from 'react-modal';
import {useTranslation} from "react-i18next";

Modal.setAppElement('#root')
if (Modal.defaultStyles) {
    if (Modal.defaultStyles.overlay) {
        if (Modal.defaultStyles.overlay.backgroundColor) {
            Modal.defaultStyles.overlay.backgroundColor = '#00000016'
        }
    }
}

const customStyles = {
    content : {
//        width: '80%',
//        maxWidth: '300px',
        // height: '200px',
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        border: 'none',
        background: 'white',
        padding: "0",
        borderRadius: 0,
        minWidth: '300px',
    }
};

export interface IDialogOkProps {
    isOpen: boolean,
    title?: string,
    children?: ReactNode,
    onClose?: any,
    btnClose?: string,
    autoCloseMS?: number,
    extraClass?: string,
}

export default function DialogOk(props: IDialogOkProps) {
    const {t} = useTranslation()

    const dialogCaption = props.title ? t(props.title) : t('dialog.Message')
    const buttonCaption = props.btnClose ? t(props.btnClose) : t('button.Ok')
    const extraClass = props.extraClass ? " " + props.extraClass : ""

    useEffect(()=>{
        const id = (props.autoCloseMS && props.isOpen)
            ? setTimeout(()=>{
                props.onClose()
              }, props.autoCloseMS)
            : 0;

        return () => {
            if (id) {
                clearTimeout(id)
            }
        }
    }, [props.isOpen])

    useEffect(()=>{
        if (props.isOpen) {
            document.body.style.position = 'fixed';
            document.body.style.top = `-${window.scrollY}px`;
        } else {
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    }, [props.isOpen])

    return (
        <Modal
            style={customStyles}
            isOpen={props.isOpen}

            onRequestClose={props.onClose}>
            <div className={"modalDialog" + extraClass}>
                <div className="mainArea">
                    <div className="title ">
                        <h2>{dialogCaption}</h2>
                    </div>
                    <div className="content">
                        {props.children}
                    </div>
                    {
                        (props.onClose) && (
                            <div className="buttons">
                                <button onClick={props.onClose}>{buttonCaption}</button>
                            </div>
                        )
                    }
                </div>
            </div>
        </Modal>
    )
}

export function DialogError(props: IDialogOkProps) {
//    return DialogOk({...props, title: "dialog.Error", extraClass: "modalDialogError"})
    return DialogOk({title: "dialog.Error", extraClass: "modalDialogError", ...props})
}

export interface IDialogLoadingProps {
    isOpen: boolean,
    title?: string,
    children?: ReactNode
}

export function DialogLoading(props: IDialogLoadingProps) {
    return DialogOk({title: "dialog.Message", extraClass: "modalDialogLoading", ...props})
}

