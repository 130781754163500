import React from 'react';
import {useTranslation} from "react-i18next";
import logo from '../logo.svg';
import Login from "../Login/Login";
import {useLocation, Navigate, NavLink, useNavigate} from "react-router-dom";

export default function NotFound() {
    const {t, i18n} = useTranslation()
//    let navigate = useNavigate();

    // if (true) {
    //     return (
    //         <Route path="*" element={<Navigate to="/login" />} />
    //     )
    // }

    const location = useLocation();
    console.log(location.pathname);

    return (
        <div className="App">
            <header className="App-header">
                <p>
                    NOR FOUND and save to reload {t('Settings')}.
                    <br/>
                    {location.pathname}
                </p>
            </header>
        </div>
    )
}
