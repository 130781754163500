import {useRecoilState, useResetRecoilState} from "recoil";
import {userLoggedIn} from "../atoms";
import React, {useEffect} from "react";
import {Route, Navigate, NavLink, useNavigate} from "react-router-dom";
import globals from "../globals";

export default function Logout() {
    const resetUserState = useResetRecoilState(userLoggedIn);

    const [user] = useRecoilState(userLoggedIn);

    useEffect(()=>{
        resetUserState()
    }, [])

    if (user.isLoggedIn) {
        return (
            <div>Please wait...</div>
        )
    }

    return (
        <Navigate to="/" replace />
    )

}